/* $picton-blue: #3190e8;
$curious-blue: #2780dc; */
:root {
  --primaryColor: #333;
}

.full-width-banner.fluid-true {
  margin-left: -15px;
  margin-right: -15px;
  overflow: hidden;
}
@media (min-width: 1200px) {
  .full-width-banner.fluid-true {
    margin-left: 0;
    margin-right: 0;
  }
}
.full-width-banner.livetv-wrap {
  background-color: #fff;
  margin-top: 0;
}
@media (max-width: 768.98px) {
  .full-width-banner.livetv-wrap {
    margin-bottom: 0;
  }
}
@media (min-width: 544px) {
  .full-width-banner.livetv-wrap {
    background-color: #d9d9f2;
    padding: 45px 0 50px;
  }
}
@media (min-width: 992px) {
  .full-width-banner.livetv-wrap .container {
    max-width: 1280px;
  }
}
.full-width-banner.livetv-wrap .fa-circle {
  font-size: 10px;
  color: #e53636;
  transform: translateY(-3px);
}

.livetv-mobile-header {
  text-transform: uppercase;
}
@media (max-width: 768.98px) {
  .livetv-mobile-header {
    padding: 0 20px;
  }
}
.livetv-mobile-header .title-left {
  font-size: 22px;
}
.livetv-mobile-header .title-right {
  font-size: 13px;
}

.ITC-figure {
  margin-bottom: 0;
}